const _jsxFileName = "/home/runner/work/heinzerling-web/heinzerling-web/app/js/components/ElevatorsViewRoute.tsx"; function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }import { AdminLayout, useTranslation } from "@opendash/core";
import { ParseObject } from "@opendash/plugin-parse";
import { Divider } from "antd";
import Parse from "parse";
import { useParseGet, useParseQuery } from "parse-hooks";
import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Aufzug, Einzelauftrag } from "../parse";
import { ErrorMessage } from "./ErrorMessage";
import { OrdersList } from "./OrdersList";

export const ElevatorsViewRoute = React.memo(function ElevatorsViewRoute() {
  const t = useTranslation();
  const navigate = useNavigate();

  const { elevatorId } = useParams();

  const elevator = useParseGet(Aufzug, elevatorId, {
    includes: ["Kunde"],
  });

  const orderQuery = React.useMemo(
    () =>
      new Parse.Query(Einzelauftrag)
        .equalTo("Aufzug", elevator.result)
        .exists("DatumMitUhrzeit")
        .exists("Kunde")
        .descending("DatumMitUhrzeit")
        .include("Kunde")
        .include("Aufzug")
        .limit(1000000),
    [_optionalChain([elevator, 'access', _ => _.result, 'optionalAccess', _2 => _2.id])]
  );

  const orders = useParseQuery(orderQuery);

  if (!elevator.result || elevator.error) {
    return (
      React.createElement(ErrorMessage, {
        icon: "fa:exclamation-circle",
        title: "Aufzug nicht gefunden"  ,
        message: "Unter dieser URL konnte kein Aufzug gefunden werden."       , __self: this, __source: {fileName: _jsxFileName, lineNumber: 39}}
      )
    );
  }

  return (
    React.createElement(AdminLayout, { contentPadding: true, __self: this, __source: {fileName: _jsxFileName, lineNumber: 48}}
      , React.createElement(ParseObject, {
        object: elevator.result,
        fields: ["Nummer", "Strasse", "PLZ", "Ort"],
        editFields: ["Nummer", "Strasse", "PLZ", "Ort"], __self: this, __source: {fileName: _jsxFileName, lineNumber: 49}}
      )
      , React.createElement(Divider, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 54}} )

      , React.createElement(OrdersList, {
        rows: orders.result,
        loading: orders.loading,
        hideElevatorLink: true, __self: this, __source: {fileName: _jsxFileName, lineNumber: 56}}
      )
    )
  );
});
