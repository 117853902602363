import Parse from "parse";















export class Monteur extends Parse.Object {
  static __initStatic() {this.className = "Monteur"}

  constructor(data) {
    super("Monteur", data );
  }

  get Name() {
    return super.get("Name");
  }
  set Name(value) {
    super.set("Name", value);
  }
  get sqlCreatedAt() {
    return super.get("sqlCreatedAt");
  }
  set sqlCreatedAt(value) {
    super.set("sqlCreatedAt", value);
  }
  get sqlDeleted() {
    return super.get("sqlDeleted");
  }
  set sqlDeleted(value) {
    super.set("sqlDeleted", value);
  }
  get sqlRef() {
    return super.get("sqlRef");
  }
  set sqlRef(value) {
    super.set("sqlRef", value);
  }
  get sqlUpdatedAt() {
    return super.get("sqlUpdatedAt");
  }
  set sqlUpdatedAt(value) {
    super.set("sqlUpdatedAt", value);
  }
  get SuchIndex() {
    return super.get("SuchIndex");
  }
  set SuchIndex(value) {
    super.set("SuchIndex", value);
  }
} Monteur.__initStatic();

Parse.Object.registerSubclass("Monteur", Monteur);
