const _jsxFileName = "/home/runner/work/heinzerling-web/heinzerling-web/app/js/components/ElevatorsListRoute.tsx"; function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }import { AdminLayout, useTranslation } from "@opendash/core";
import { Icon } from "@opendash/icons";
import { AdminToolbar } from "@opendash/ui";
import { List } from "antd";
import Parse from "parse";
import { useParseQuery } from "parse-hooks";
import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { Aufzug, } from "../parse";

export const ElevatorsListRoute = React.memo(function ElevatorsListRoute() {
  const t = useTranslation();
  const navigate = useNavigate();

  const [searchString, setSearchString] = React.useState("");

  const query = React.useMemo(
    () =>
      new Parse.Query(Aufzug)
        .ascending("Nummer")
        .exists("Kunde")
        .limit(1000000)
        .include("Kunde"),
    []
  );

  const entries = useParseQuery(query);

  const rows = React.useMemo(() => {
    const search = searchString.toLowerCase();

    return entries.result
      .filter((row) => {
        const aufzug = row ;
        const kunde = aufzug.get("Kunde") ;

        if (_optionalChain([aufzug, 'access', _ => _.get, 'call', _2 => _2("Nummer"), 'optionalAccess', _3 => _3.toString, 'call', _4 => _4(), 'access', _5 => _5.startsWith, 'call', _6 => _6(search)])) {
          return true;
        }

        if (_optionalChain([kunde, 'access', _7 => _7.get, 'call', _8 => _8("Name"), 'optionalAccess', _9 => _9.toLowerCase, 'call', _10 => _10(), 'access', _11 => _11.includes, 'call', _12 => _12(search)])) {
          return true;
        }

        const address = [
          aufzug.get("Strasse"),
          aufzug.get("PLZ"),
          aufzug.get("Ort"),
        ].join(" ");

        if (address.includes(search)) {
          return true;
        }

        return false;
      })
      .sort((a, b) => {
        return parseFloat(a.get("Nummer")) - parseFloat(b.get("Nummer"));
      });
  }, [entries.result, searchString]);

  return (
    React.createElement(AdminLayout, { contentPadding: true, __self: this, __source: {fileName: _jsxFileName, lineNumber: 63}}
      , React.createElement(AdminToolbar, {
        title: t("app:admin.elevators.list_title"),
        description: t("app:admin.elevators.list_description"),
        padding: false,
        // actions={[
        //   <Button
        //     key="new"
        //     type="primary"
        //     icon={<Icon icon="fa:plus" />}
        //     children={t("app:admin.order.action_new")}
        //     onClick={() => {
        //       createObject("Auftrag").then((objectId) => {
        //         entries.reload();
        //       });
        //     }}
        //   />,
        // ]}
        search: searchString,
        onSearch: setSearchString, __self: this, __source: {fileName: _jsxFileName, lineNumber: 64}}
      )

      , React.createElement(List, {
        loading: entries.loading,
        dataSource: rows,
        renderItem: (row) => {
          const aufzug = row ;
          const kunde = aufzug.get("Kunde") ;

          return (
            React.createElement(Link, { to: `/elevators/${aufzug.id}`, __self: this, __source: {fileName: _jsxFileName, lineNumber: 93}}
              , React.createElement(List.Item, {
                actions: [React.createElement(Icon, { key: "icon", icon: "fa:chevron-right", __self: this, __source: {fileName: _jsxFileName, lineNumber: 95}} )], __self: this, __source: {fileName: _jsxFileName, lineNumber: 94}}

                , React.createElement(List.Item.Meta, {
                  title: t("app:admin.elevators.view_title", {
                    name: aufzug.get("Nummer") || "",
                  }),
                  description: 
                    React.createElement('span', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 102}}
                      , kunde.get("Name"), " - "  , aufzug.get("Strasse"), " "
                      , aufzug.get("PLZ"), " " , aufzug.get("Ort")
                    )
                  , __self: this, __source: {fileName: _jsxFileName, lineNumber: 97}}
                )
              )
            )
          );
        },
        rowKey: (row) => row.id,
        pagination: {
          defaultCurrent: 1,
          defaultPageSize: 10,
        }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 85}}
      )
    )
  );
});
