import Parse from "parse";


























































export class Einzelauftrag extends Parse.Object {
  static __initStatic() {this.className = "Einzelauftrag"}

  constructor(data) {
    super("Einzelauftrag", data );
  }

  get Abgeschlossen() {
    return super.get("Abgeschlossen");
  }
  set Abgeschlossen(value) {
    super.set("Abgeschlossen", value);
  }
  get Arbeiten() {
    return super.get("Arbeiten");
  }
  set Arbeiten(value) {
    super.set("Arbeiten", value);
  }
  get ArbeitenHTML() {
    return super.get("ArbeitenHTML");
  }
  set ArbeitenHTML(value) {
    super.set("ArbeitenHTML", value);
  }
  get Aufzug() {
    return super.get("Aufzug");
  }
  set Aufzug(value) {
    super.set("Aufzug", value);
  }
  get belegtext() {
    return super.get("belegtext");
  }
  set belegtext(value) {
    super.set("belegtext", value);
  }
  get Bemerkungen() {
    return super.get("Bemerkungen");
  }
  set Bemerkungen(value) {
    super.set("Bemerkungen", value);
  }
  get BemerkungenHTML() {
    return super.get("BemerkungenHTML");
  }
  set BemerkungenHTML(value) {
    super.set("BemerkungenHTML", value);
  }
  get Bestellnummer() {
    return super.get("Bestellnummer");
  }
  set Bestellnummer(value) {
    super.set("Bestellnummer", value);
  }
  get BestellnummerDatum() {
    return super.get("BestellnummerDatum");
  }
  set BestellnummerDatum(value) {
    super.set("BestellnummerDatum", value);
  }
  get BestellungKontakt() {
    return super.get("BestellungKontakt");
  }
  set BestellungKontakt(value) {
    super.set("BestellungKontakt", value);
  }
  get Datum() {
    return super.get("Datum");
  }
  set Datum(value) {
    super.set("Datum", value);
  }
  get DatumMitUhrzeit() {
    return super.get("DatumMitUhrzeit");
  }
  set DatumMitUhrzeit(value) {
    super.set("DatumMitUhrzeit", value);
  }
  get Gesamtauftrag() {
    return super.get("Gesamtauftrag");
  }
  set Gesamtauftrag(value) {
    super.set("Gesamtauftrag", value);
  }
  get Gesperrt() {
    return super.get("Gesperrt");
  }
  set Gesperrt(value) {
    super.set("Gesperrt", value);
  }
  get Kunde() {
    return super.get("Kunde");
  }
  set Kunde(value) {
    super.set("Kunde", value);
  }
  get local_arbeiten() {
    return super.get("local_arbeiten");
  }
  set local_arbeiten(value) {
    super.set("local_arbeiten", value);
  }
  get local_arbeitenHTML() {
    return super.get("local_arbeitenHTML");
  }
  set local_arbeitenHTML(value) {
    super.set("local_arbeitenHTML", value);
  }
  get local_bemerkungen() {
    return super.get("local_bemerkungen");
  }
  set local_bemerkungen(value) {
    super.set("local_bemerkungen", value);
  }
  get local_bemerkungenHTML() {
    return super.get("local_bemerkungenHTML");
  }
  set local_bemerkungenHTML(value) {
    super.set("local_bemerkungenHTML", value);
  }
  get mailDelivered() {
    return super.get("mailDelivered");
  }
  set mailDelivered(value) {
    super.set("mailDelivered", value);
  }
  get mailSend() {
    return super.get("mailSend");
  }
  set mailSend(value) {
    super.set("mailSend", value);
  }
  get mailto() {
    return super.get("mailto");
  }
  set mailto(value) {
    super.set("mailto", value);
  }
  get MobilErzeugt() {
    return super.get("MobilErzeugt");
  }
  set MobilErzeugt(value) {
    super.set("MobilErzeugt", value);
  }
  get Monteure() {
    return super.get("Monteure");
  }
  set Monteure(value) {
    super.set("Monteure", value);
  }
  get Nachtext() {
    return super.get("Nachtext");
  }
  set Nachtext(value) {
    super.set("Nachtext", value);
  }
  get NachtextHTML() {
    return super.get("NachtextHTML");
  }
  set NachtextHTML(value) {
    super.set("NachtextHTML", value);
  }
  get Nummer() {
    return super.get("Nummer");
  }
  set Nummer(value) {
    super.set("Nummer", value);
  }
  get Nummern() {
    return super.get("Nummern");
  }
  set Nummern(value) {
    super.set("Nummern", value);
  }
  get pdf() {
    return super.get("pdf");
  }
  set pdf(value) {
    super.set("pdf", value);
  }
  get sqlCreatedAt() {
    return super.get("sqlCreatedAt");
  }
  set sqlCreatedAt(value) {
    super.set("sqlCreatedAt", value);
  }
  get sqlDeleted() {
    return super.get("sqlDeleted");
  }
  set sqlDeleted(value) {
    super.set("sqlDeleted", value);
  }
  get sqlRef() {
    return super.get("sqlRef");
  }
  set sqlRef(value) {
    super.set("sqlRef", value);
  }
  get sqlUpdatedAt() {
    return super.get("sqlUpdatedAt");
  }
  set sqlUpdatedAt(value) {
    super.set("sqlUpdatedAt", value);
  }
  get Sync_status() {
    return super.get("Sync_status");
  }
  set Sync_status(value) {
    super.set("Sync_status", value);
  }
  get Typ() {
    return super.get("Typ");
  }
  set Typ(value) {
    super.set("Typ", value);
  }
  get Uhrzeit() {
    return super.get("Uhrzeit");
  }
  set Uhrzeit(value) {
    super.set("Uhrzeit", value);
  }
  get Unterschrift_Kunde() {
    return super.get("Unterschrift_Kunde");
  }
  set Unterschrift_Kunde(value) {
    super.set("Unterschrift_Kunde", value);
  }
  get Unterschrift_Monteur() {
    return super.get("Unterschrift_Monteur");
  }
  set Unterschrift_Monteur(value) {
    super.set("Unterschrift_Monteur", value);
  }
  get user() {
    return super.get("user");
  }
  set user(value) {
    super.set("user", value);
  }
  get Vertragsart() {
    return super.get("Vertragsart");
  }
  set Vertragsart(value) {
    super.set("Vertragsart", value);
  }
  get Vortext() {
    return super.get("Vortext");
  }
  set Vortext(value) {
    super.set("Vortext", value);
  }
  get VortextHTML() {
    return super.get("VortextHTML");
  }
  set VortextHTML(value) {
    super.set("VortextHTML", value);
  }
  get Zeitstempel() {
    return super.get("Zeitstempel");
  }
  set Zeitstempel(value) {
    super.set("Zeitstempel", value);
  }
} Einzelauftrag.__initStatic();

Parse.Object.registerSubclass("Einzelauftrag", Einzelauftrag);
