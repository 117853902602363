const _jsxFileName = "/home/runner/work/heinzerling-web/heinzerling-web/app/js/components/OrderPrintRoute.tsx";import React from "react";

import { useParams } from "react-router-dom";
import { PrintView } from "./PrintView";

export const OrderPrintRoute = React.memo(function OrderPrintRoute() {
  const { orderId } = useParams();

  return (
    React.createElement(PrintView, {
      preview: `https://heinzerling-print-service.apps.openinc.dev/render/auftragsbestaetigung/${orderId}`,
      download: `https://heinzerling-print-service.apps.openinc.dev/print/auftragsbestaetigung/${orderId}`, __self: this, __source: {fileName: _jsxFileName, lineNumber: 10}}
    )
  );
});
