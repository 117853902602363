import Parse from "parse";





















export class MonteurAuftrag extends Parse.Object {
  static __initStatic() {this.className = "MonteurAuftrag"}

  constructor(data) {
    super("MonteurAuftrag", data );
  }

  get Auftrag() {
    return super.get("Auftrag");
  }
  set Auftrag(value) {
    super.set("Auftrag", value);
  }
  get Kategorie() {
    return super.get("Kategorie");
  }
  set Kategorie(value) {
    super.set("Kategorie", value);
  }
  get Monteur() {
    return super.get("Monteur");
  }
  set Monteur(value) {
    super.set("Monteur", value);
  }
  get sqlCreatedAt() {
    return super.get("sqlCreatedAt");
  }
  set sqlCreatedAt(value) {
    super.set("sqlCreatedAt", value);
  }
  get sqlDeleted() {
    return super.get("sqlDeleted");
  }
  set sqlDeleted(value) {
    super.set("sqlDeleted", value);
  }
  get sqlRef() {
    return super.get("sqlRef");
  }
  set sqlRef(value) {
    super.set("sqlRef", value);
  }
  get sqlUpdatedAt() {
    return super.get("sqlUpdatedAt");
  }
  set sqlUpdatedAt(value) {
    super.set("sqlUpdatedAt", value);
  }
  get Stunden() {
    return super.get("Stunden");
  }
  set Stunden(value) {
    super.set("Stunden", value);
  }
  get Vorgegeben() {
    return super.get("Vorgegeben");
  }
  set Vorgegeben(value) {
    super.set("Vorgegeben", value);
  }
} MonteurAuftrag.__initStatic();

Parse.Object.registerSubclass("MonteurAuftrag", MonteurAuftrag);
