import Parse from "parse";




















export class Kunde extends Parse.Object {
  static __initStatic() {this.className = "Kunde"}

  constructor(data) {
    super("Kunde", data );
  }

  get Kundennummer() {
    return super.get("Kundennummer");
  }
  set Kundennummer(value) {
    super.set("Kundennummer", value);
  }
  get Name() {
    return super.get("Name");
  }
  set Name(value) {
    super.set("Name", value);
  }
  get Name2() {
    return super.get("Name2");
  }
  set Name2(value) {
    super.set("Name2", value);
  }
  get Name3() {
    return super.get("Name3");
  }
  set Name3(value) {
    super.set("Name3", value);
  }
  get Ort() {
    return super.get("Ort");
  }
  set Ort(value) {
    super.set("Ort", value);
  }
  get PLZ() {
    return super.get("PLZ");
  }
  set PLZ(value) {
    super.set("PLZ", value);
  }
  get sqlCreatedAt() {
    return super.get("sqlCreatedAt");
  }
  set sqlCreatedAt(value) {
    super.set("sqlCreatedAt", value);
  }
  get sqlDeleted() {
    return super.get("sqlDeleted");
  }
  set sqlDeleted(value) {
    super.set("sqlDeleted", value);
  }
  get sqlRef() {
    return super.get("sqlRef");
  }
  set sqlRef(value) {
    super.set("sqlRef", value);
  }
  get sqlUpdatedAt() {
    return super.get("sqlUpdatedAt");
  }
  set sqlUpdatedAt(value) {
    super.set("sqlUpdatedAt", value);
  }
  get Strasse() {
    return super.get("Strasse");
  }
  set Strasse(value) {
    super.set("Strasse", value);
  }
} Kunde.__initStatic();

Parse.Object.registerSubclass("Kunde", Kunde);
