import Parse from "parse";





















export class Ansprechpartner extends Parse.Object {
  static __initStatic() {this.className = "Ansprechpartner"}

  constructor(data) {
    super("Ansprechpartner", data );
  }

  get Aufzug() {
    return super.get("Aufzug");
  }
  set Aufzug(value) {
    super.set("Aufzug", value);
  }
  get Kunde() {
    return super.get("Kunde");
  }
  set Kunde(value) {
    super.set("Kunde", value);
  }
  get Name() {
    return super.get("Name");
  }
  set Name(value) {
    super.set("Name", value);
  }
  get sqlCreatedAt() {
    return super.get("sqlCreatedAt");
  }
  set sqlCreatedAt(value) {
    super.set("sqlCreatedAt", value);
  }
  get sqlDeleted() {
    return super.get("sqlDeleted");
  }
  set sqlDeleted(value) {
    super.set("sqlDeleted", value);
  }
  get sqlRef() {
    return super.get("sqlRef");
  }
  set sqlRef(value) {
    super.set("sqlRef", value);
  }
  get sqlUpdatedAt() {
    return super.get("sqlUpdatedAt");
  }
  set sqlUpdatedAt(value) {
    super.set("sqlUpdatedAt", value);
  }
  get Telefon() {
    return super.get("Telefon");
  }
  set Telefon(value) {
    super.set("Telefon", value);
  }
  get TelefonMobil() {
    return super.get("TelefonMobil");
  }
  set TelefonMobil(value) {
    super.set("TelefonMobil", value);
  }
} Ansprechpartner.__initStatic();

Parse.Object.registerSubclass("Ansprechpartner", Ansprechpartner);
