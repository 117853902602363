 function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }// @ts-check

import "antd/dist/antd.min.css";

import { init, StorageAdapterLS } from "@opendash/core";
import { registerIconPack } from "@opendash/icons";

import { Parse, ParsePlugin, $parse } from "@opendash/plugin-parse";

import "./parse.config";

import { ElevatorsListRoute } from "./components/ElevatorsListRoute";
import { ElevatorsViewRoute } from "./components/ElevatorsViewRoute";
import { OrdersRoute } from "./components/OrdersRoute";
import { OrderPrintRoute } from "./components/OrderPrintRoute";
import { WeekOverviewRoute } from "./components/WeekOverviewRoute";
import { OrdersViewRoute } from "./components/OrdersViewRoute";

init("opendash", async (factory) => {
  const url = new URL(window.location.href);

  const isEmbedded =
    !!url.searchParams.get("embedded") ||
    window.localStorage.getItem("opendash/embedded") === "true";

  if (isEmbedded) {
    window.localStorage.setItem("opendash/embedded", "true");

    url.searchParams.delete("embedded");

    window.history.replaceState(null, "", url.href);
  }

  // Logo:

  factory.ui.setLogoImage(require("../assets/logo-icon.png"));

  if (isEmbedded) {
    factory.ui.disableHeader();
    factory.ui.disableFooter();
  }

  // Icons
  // @ts-ignore
  registerIconPack(await import("@opendash/icons/dist/fa-regular.json"));

  // Translations:

  factory.registerLanguage("de", "Deutsch");

  factory.registerTranslationResolver(
    "de",
    "app",
    () => import("./translations/de/app.json")
  );

  factory.registerTranslationResolver(
    "de",
    "parse-custom",
    () => import("./translations/de/parse-custom.json")
  );

  factory.registerAntDesignTranslation(
    "de",
    () => import("antd/lib/locale/de_DE")
  );

  // Adapter + Plugins

  factory.registerDeviceStorageAdapter(new StorageAdapterLS());

  await factory.use(
    new ParsePlugin({
      useLiveQueries: false,
      authLdapActive: true,
    })
  );

  // Routes

  factory.registerRoute({
    path: "/",
    redirectPath: "/elevators",
  });

  factory.registerRoute({
    path: "/elevators",
    component: async () => ({ default: ElevatorsListRoute }),
  });

  factory.registerRoute({
    path: "/elevators/:elevatorId",
    component: async () => ({ default: ElevatorsViewRoute }),
  });

  factory.registerRoute({
    path: "/orders",
    component: async () => ({ default: OrdersRoute }),
  });

  factory.registerRoute({
    path: "/orders/:orderId",
    component: async () => ({ default: OrdersViewRoute }),
  });

  factory.registerRoute({
    path: "/overview",
    component: async () => ({ default: WeekOverviewRoute }),
  });

  factory.registerRoute({
    path: "/overview/:startParam/:endParam",
    component: async () => ({ default: WeekOverviewRoute }),
  });

  factory.registerRoute({
    path: "/print-order/:orderId",
    component: async () => ({ default: OrderPrintRoute }),
  });

  // Parse Config

  $parse.ui.setClassConfig({
    className: "_User",
    titleFields: ["username"],
    displayFields: [
      "name",
      "username",
      "email",
      "verwaltung",
      "monteur",
      "ldap",
    ],
    createFields: [
      "name",
      "username",
      "email",
      "verwaltung",
      "monteur",
      "password",
    ],
    editFields: [
      "name",
      "username",
      "email",
      "verwaltung",
      "monteur",
      "password",
    ],
    disableACLEditing: true,
  });

  $parse.ui.setDefaultView("_User", {
    type: "table",
  });

  $parse.ui.setClassConfig({
    className: "Artikel",
    titleFields: ["Name"],
    displayFields: [],
    createFields: [],
    editFields: [],
    disableACLEditing: true,
  });

  $parse.ui.setDefaultView("Artikel", {
    type: "table",
  });

  $parse.ui.setClassConfig({
    className: "Monteur",
    titleFields: ["Name"],
    displayFields: [],
    createFields: [],
    editFields: [],
    disableACLEditing: true,
  });

  $parse.ui.setDefaultView("Monteur", {
    type: "table",
  });

  $parse.ui.setClassConfig({
    className: "Textvorlagen",
    titleFields: ["Titel"],
    displayFields: ["Titel", "Text"],
    createFields: ["Titel", "Text"],
    editFields: ["Titel", "Text"],
    defaultACL: () => {
      return {
        [_optionalChain([Parse, 'access', _ => _.User, 'access', _2 => _2.current, 'call', _3 => _3(), 'optionalAccess', _4 => _4.id])]: { read: true, write: true },
      };
    },
  });

  $parse.ui.setDefaultView("Textvorlagen", {
    type: "table",
  });
}).then((app) => {});
