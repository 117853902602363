import Parse from "parse";























export class Aufzug extends Parse.Object {
  static __initStatic() {this.className = "Aufzug"}

  constructor(data) {
    super("Aufzug", data );
  }

  get Kunde() {
    return super.get("Kunde");
  }
  set Kunde(value) {
    super.set("Kunde", value);
  }
  get LetzteWartung() {
    return super.get("LetzteWartung");
  }
  set LetzteWartung(value) {
    super.set("LetzteWartung", value);
  }
  get Name() {
    return super.get("Name");
  }
  set Name(value) {
    super.set("Name", value);
  }
  get Nummer() {
    return super.get("Nummer");
  }
  set Nummer(value) {
    super.set("Nummer", value);
  }
  get Ort() {
    return super.get("Ort");
  }
  set Ort(value) {
    super.set("Ort", value);
  }
  get PLZ() {
    return super.get("PLZ");
  }
  set PLZ(value) {
    super.set("PLZ", value);
  }
  get Schluesseldepot() {
    return super.get("Schluesseldepot");
  }
  set Schluesseldepot(value) {
    super.set("Schluesseldepot", value);
  }
  get sqlCreatedAt() {
    return super.get("sqlCreatedAt");
  }
  set sqlCreatedAt(value) {
    super.set("sqlCreatedAt", value);
  }
  get sqlDeleted() {
    return super.get("sqlDeleted");
  }
  set sqlDeleted(value) {
    super.set("sqlDeleted", value);
  }
  get sqlRef() {
    return super.get("sqlRef");
  }
  set sqlRef(value) {
    super.set("sqlRef", value);
  }
  get sqlUpdatedAt() {
    return super.get("sqlUpdatedAt");
  }
  set sqlUpdatedAt(value) {
    super.set("sqlUpdatedAt", value);
  }
  get Strasse() {
    return super.get("Strasse");
  }
  set Strasse(value) {
    super.set("Strasse", value);
  }
} Aufzug.__initStatic();

Parse.Object.registerSubclass("Aufzug", Aufzug);
