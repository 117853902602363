const _jsxFileName = "/home/runner/work/heinzerling-web/heinzerling-web/app/js/components/OrdersViewRoute.tsx"; function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }import { AdminLayout, useTranslation } from "@opendash/core";
import { ParseObject } from "@opendash/plugin-parse";
import { Card, Divider, Image, List } from "antd";
import Parse from "parse";
import { useParseGet, useParseQuery } from "parse-hooks";
import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Einzelauftrag, EinzelauftragFoto } from "../parse";
import { ErrorMessage } from "./ErrorMessage";
import { PrintView } from "./PrintView";

export const OrdersViewRoute = React.memo(function OrdersViewRoute() {
  const t = useTranslation();
  const navigate = useNavigate();

  const { orderId } = useParams();

  const order = useParseGet(Einzelauftrag, orderId, {
    includes: ["Kunde", "Aufzug"],
  });

  const pdf = _optionalChain([order, 'access', _ => _.result, 'optionalAccess', _2 => _2.get, 'call', _3 => _3("pdf"), 'optionalAccess', _4 => _4.url, 'call', _5 => _5()]);

  if (!order.result || order.error) {
    return (
      React.createElement(ErrorMessage, {
        icon: "fa:exclamation-circle",
        title: "Aufzug nicht gefunden"  ,
        message: "Unter dieser URL konnte kein Aufzug gefunden werden."       , __self: this, __source: {fileName: _jsxFileName, lineNumber: 26}}
      )
    );
  }

  return (
    React.createElement(AdminLayout, { contentPadding: true, __self: this, __source: {fileName: _jsxFileName, lineNumber: 35}}
      , React.createElement(ParseObject, {
        object: order.result,
        fields: [
          "Nummer",
          "DatumMitUhrzeit",
          "Typ",
          "Vertragsart",
          "local_bemerkungen",
          "local_arbeiten",
        ],
        editFields: ["local_bemerkungen", "local_arbeiten"],
        disableDelete: true, __self: this, __source: {fileName: _jsxFileName, lineNumber: 36}}
      )

      , React.createElement(OrderMedia, { order: order.result, __self: this, __source: {fileName: _jsxFileName, lineNumber: 50}} )

      , React.createElement(Divider, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 52}} )

      , pdf && (
        React.createElement(PrintView, {
          title: t("app:orders.workreport.title"),
          preview: pdf,
          download: pdf, __self: this, __source: {fileName: _jsxFileName, lineNumber: 55}}
        )
      )

      , !pdf && (
        React.createElement(PrintView, {
          title: t("app:orders.workreport.title_preview"),
          preview: `https://heinzerling-print-service.apps.openinc.dev/render/auftragsbestaetigung/${orderId}`,
          download: `https://heinzerling-print-service.apps.openinc.dev/print/auftragsbestaetigung/${orderId}`, __self: this, __source: {fileName: _jsxFileName, lineNumber: 63}}
        )
      )
    )
  );
});





export const OrderMedia = React.memo(function OrderMedia({
  order,
}) {
  const t = useTranslation();

  const query = React.useMemo(
    () =>
      new Parse.Query(EinzelauftragFoto)
        .equalTo("einzelauftrag", order)
        .ascending("createdAt"),
    [_optionalChain([order, 'optionalAccess', _6 => _6.id])]
  );

  const images = useParseQuery(query);

  if (images.result.length === 0) {
    return null;
  }

  return (
    React.createElement(React.Fragment, null
      , React.createElement(Divider, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 98}} )

      , React.createElement(Image.PreviewGroup, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 100}}
        , React.createElement(List, {
          grid: {
            gutter: 16,
            xs: 1,
            sm: 2,
            md: 4,
            lg: 4,
            xl: 5,
            xxl: 6,
          },
          rowKey: (row) => row.id,
          dataSource: images.result,
          loading: images.loading,
          renderItem: (image) => {
            const alt = image.get("name");
            const src = "data:image/png;base64," + image.get("imageBase64");
            return (
              React.createElement(List.Item, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 118}}
                , React.createElement(Card, {
                  hoverable: true,
                  cover: React.createElement(Image, { alt: alt, src: src, __self: this, __source: {fileName: _jsxFileName, lineNumber: 121}} ),
                  children: 
                    React.createElement(Card.Meta, {
                      title: alt,
                      description: t("app:orders.fotos.card_description"), __self: this, __source: {fileName: _jsxFileName, lineNumber: 123}}
                    )
                  , __self: this, __source: {fileName: _jsxFileName, lineNumber: 119}}
                )
              )
            );
          }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 101}}
        )
      )
    )
  );
});
