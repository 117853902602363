import Parse from "parse";














export class EinzelauftragFoto extends Parse.Object {
  static __initStatic() {this.className = "EinzelauftragFoto"}

  constructor(data) {
    super("EinzelauftragFoto", data );
  }

  get einzelauftrag() {
    return super.get("einzelauftrag");
  }
  set einzelauftrag(value) {
    super.set("einzelauftrag", value);
  }
  get imageBase64() {
    return super.get("imageBase64");
  }
  set imageBase64(value) {
    super.set("imageBase64", value);
  }
  get name() {
    return super.get("name");
  }
  set name(value) {
    super.set("name", value);
  }
} EinzelauftragFoto.__initStatic();

Parse.Object.registerSubclass("EinzelauftragFoto", EinzelauftragFoto);
