export function formatDate(date) {
  if (!date || !date.toLocaleDateString) {
    return "";
  }

  return date.toLocaleDateString("de-DE", {
    month: "2-digit",
    day: "2-digit",
    year: "numeric",
  });
}

export function formatTime(date) {
  if (!date || !date.toLocaleTimeString) {
    return "";
  }

  return date.toLocaleTimeString("de-DE", {
    hour: "2-digit",
    minute: "2-digit",
  });
}

export function formatDateTime(date) {
  if (!date) {
    return "";
  }

  date = new Date(date);

  return formatDate(date) + ", " + formatTime(date);
}
