import Parse from "parse";





























export class ArtikelAuftrag extends Parse.Object {
  static __initStatic() {this.className = "ArtikelAuftrag"}

  constructor(data) {
    super("ArtikelAuftrag", data );
  }

  get Anzahl() {
    return super.get("Anzahl");
  }
  set Anzahl(value) {
    super.set("Anzahl", value);
  }
  get Artikel() {
    return super.get("Artikel");
  }
  set Artikel(value) {
    super.set("Artikel", value);
  }
  get Auftrag() {
    return super.get("Auftrag");
  }
  set Auftrag(value) {
    super.set("Auftrag", value);
  }
  get Beschreibung() {
    return super.get("Beschreibung");
  }
  set Beschreibung(value) {
    super.set("Beschreibung", value);
  }
  get Beschreibung2() {
    return super.get("Beschreibung2");
  }
  set Beschreibung2(value) {
    super.set("Beschreibung2", value);
  }
  get Beschreibung2HTML() {
    return super.get("Beschreibung2HTML");
  }
  set Beschreibung2HTML(value) {
    super.set("Beschreibung2HTML", value);
  }
  get Beschreibung3() {
    return super.get("Beschreibung3");
  }
  set Beschreibung3(value) {
    super.set("Beschreibung3", value);
  }
  get Beschreibung3HTML() {
    return super.get("Beschreibung3HTML");
  }
  set Beschreibung3HTML(value) {
    super.set("Beschreibung3HTML", value);
  }
  get Beschreibung4() {
    return super.get("Beschreibung4");
  }
  set Beschreibung4(value) {
    super.set("Beschreibung4", value);
  }
  get Beschreibung4HTML() {
    return super.get("Beschreibung4HTML");
  }
  set Beschreibung4HTML(value) {
    super.set("Beschreibung4HTML", value);
  }
  get BeschreibungHTML() {
    return super.get("BeschreibungHTML");
  }
  set BeschreibungHTML(value) {
    super.set("BeschreibungHTML", value);
  }
  get PositionsNummer() {
    return super.get("PositionsNummer");
  }
  set PositionsNummer(value) {
    super.set("PositionsNummer", value);
  }
  get sqlCreatedAt() {
    return super.get("sqlCreatedAt");
  }
  set sqlCreatedAt(value) {
    super.set("sqlCreatedAt", value);
  }
  get sqlDeleted() {
    return super.get("sqlDeleted");
  }
  set sqlDeleted(value) {
    super.set("sqlDeleted", value);
  }
  get sqlRef() {
    return super.get("sqlRef");
  }
  set sqlRef(value) {
    super.set("sqlRef", value);
  }
  get sqlUpdatedAt() {
    return super.get("sqlUpdatedAt");
  }
  set sqlUpdatedAt(value) {
    super.set("sqlUpdatedAt", value);
  }
  get Vorgegeben() {
    return super.get("Vorgegeben");
  }
  set Vorgegeben(value) {
    super.set("Vorgegeben", value);
  }
} ArtikelAuftrag.__initStatic();

Parse.Object.registerSubclass("ArtikelAuftrag", ArtikelAuftrag);
