import Parse from "parse";











export class Textvorlagen extends Parse.Object {
  static __initStatic() {this.className = "Textvorlagen"}

  constructor(data) {
    super("Textvorlagen", data );
  }

  get Text() {
    return super.get("Text");
  }
  set Text(value) {
    super.set("Text", value);
  }
  get Titel() {
    return super.get("Titel");
  }
  set Titel(value) {
    super.set("Titel", value);
  }
} Textvorlagen.__initStatic();

Parse.Object.registerSubclass("Textvorlagen", Textvorlagen);
