import Parse from "parse";






















export class Artikel extends Parse.Object {
  static __initStatic() {this.className = "Artikel"}

  constructor(data) {
    super("Artikel", data );
  }

  get Artikelnummer() {
    return super.get("Artikelnummer");
  }
  set Artikelnummer(value) {
    super.set("Artikelnummer", value);
  }
  get Autofill() {
    return super.get("Autofill");
  }
  set Autofill(value) {
    super.set("Autofill", value);
  }
  get Beschreibung4() {
    return super.get("Beschreibung4");
  }
  set Beschreibung4(value) {
    super.set("Beschreibung4", value);
  }
  get Beschreibung4HTML() {
    return super.get("Beschreibung4HTML");
  }
  set Beschreibung4HTML(value) {
    super.set("Beschreibung4HTML", value);
  }
  get Einheit() {
    return super.get("Einheit");
  }
  set Einheit(value) {
    super.set("Einheit", value);
  }
  get fromExcelImport() {
    return super.get("fromExcelImport");
  }
  set fromExcelImport(value) {
    super.set("fromExcelImport", value);
  }
  get Name() {
    return super.get("Name");
  }
  set Name(value) {
    super.set("Name", value);
  }
  get Planansicht() {
    return super.get("Planansicht");
  }
  set Planansicht(value) {
    super.set("Planansicht", value);
  }
  get sqlCreatedAt() {
    return super.get("sqlCreatedAt");
  }
  set sqlCreatedAt(value) {
    super.set("sqlCreatedAt", value);
  }
  get sqlDeleted() {
    return super.get("sqlDeleted");
  }
  set sqlDeleted(value) {
    super.set("sqlDeleted", value);
  }
  get sqlRef() {
    return super.get("sqlRef");
  }
  set sqlRef(value) {
    super.set("sqlRef", value);
  }
  get sqlUpdatedAt() {
    return super.get("sqlUpdatedAt");
  }
  set sqlUpdatedAt(value) {
    super.set("sqlUpdatedAt", value);
  }
  get SuchIndex() {
    return super.get("SuchIndex");
  }
  set SuchIndex(value) {
    super.set("SuchIndex", value);
  }
} Artikel.__initStatic();

Parse.Object.registerSubclass("Artikel", Artikel);
