const _jsxFileName = "/home/runner/work/heinzerling-web/heinzerling-web/app/js/components/WeekOverviewRoute.tsx"; function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }import React from "react";
import Parse from "parse";
import moment from "moment";

import { AdminLayout, useTranslation } from "@opendash/core";
import { AdminToolbar } from "@opendash/ui";
import { Checkbox, DatePicker, List, Space, Tabs } from "antd";
import { useParseQuery } from "parse-hooks";
import {

  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";

import { ArtikelAuftrag, Einzelauftrag } from "../parse";
import { OrdersList } from "./OrdersList";
import { formatDate } from "../helper";

const DATE_FORMAT = "YYYY-MM-DD";

export const WeekOverviewRoute = React.memo(function WeekOverviewRoute() {
  const t = useTranslation();
  const navigate = useNavigate();

  const { startParam, endParam } = useParams();
  let [searchParams, setSearchParams] = useSearchParams();
  const [tab, setTab] = React.useState("aufträge");

  const { start, end } = React.useMemo(() => {
    const start = startParam
      ? moment(startParam, DATE_FORMAT)
      : moment().startOf("isoWeek");

    const end = endParam
      ? moment(endParam, DATE_FORMAT)
      : moment().endOf("isoWeek");

    return { start, end };
  }, [startParam, endParam]);

  React.useEffect(() => {
    if (!startParam || !endParam) {
      navigate(
        `/overview/${start.format(DATE_FORMAT)}/${end.format(DATE_FORMAT)}`,
        { replace: true }
      );
    }
  }, [startParam, endParam]);

  const aufträgeQuery = React.useMemo(() => {
    const query = new Parse.Query(Einzelauftrag)
      .exists("DatumMitUhrzeit")
      .descending("DatumMitUhrzeit")
      .greaterThan("DatumMitUhrzeit", start.toDate())
      .lessThan("DatumMitUhrzeit", end.toDate())
      .include("Kunde")
      .include("Aufzug")
      .limit(1000000);

    if (searchParams.get("showFinished") !== "true") {
      query.notEqualTo("Abgeschlossen", true);
      query.notEqualTo("mailSend", true);
    }

    return query;
  }, [start.valueOf(), end.valueOf(), searchParams.get("showFinished")]);

  const aufträge = useParseQuery(aufträgeQuery, {
    live: true,
    liveReload: true,
    reloadThrottle: 60 * 1000,
  });

  const artikelAufträgeQuery = React.useMemo(
    () =>
      new Parse.Query(ArtikelAuftrag)
        .containedIn("Auftrag", aufträge.result)
        .include("Artikel")
        .limit(1000000),
    [aufträge.result]
  );

  const artikelAufträge = useParseQuery(artikelAufträgeQuery);

  const artikelGroupMenge = React.useMemo(() => {
    const map = new Map


();

    for (const iterator of artikelAufträge.result) {
      const artikel = iterator.get("Artikel") ;
      const menge = iterator.get("Anzahl");

      if (!artikel || !artikel.get("Planansicht")) {
        continue;
      }

      if (map.has(artikel.id)) {
        map.get(artikel.id).menge += menge;
        map
          .get(artikel.id)
          .aufträge.push(_optionalChain([iterator, 'access', _ => _.get, 'call', _2 => _2("Auftrag"), 'optionalAccess', _3 => _3.get, 'call', _4 => _4("Nummer")]));
      } else {
        map.set(artikel.id, {
          artikel,
          menge,
          aufträge: [_optionalChain([iterator, 'access', _5 => _5.get, 'call', _6 => _6("Auftrag"), 'optionalAccess', _7 => _7.get, 'call', _8 => _8("Nummer")])],
        });
      }
    }

    return Array.from(map.values());
  }, [artikelAufträge.result]);

  // React.useEffect(() => {
  //   console.log(aufträge.result, artikel.result);
  // }, [aufträge.result, artikel.result]);

  return (
    React.createElement(AdminLayout, { contentPadding: true, __self: this, __source: {fileName: _jsxFileName, lineNumber: 122}}
      , React.createElement(AdminToolbar, {
        title: t("app:overview.title"),
        description: t("app:overview.time_range", {
          start: formatDate(start.toDate()),
          end: formatDate(end.toDate()),
        }),
        padding: false,
        // search={searchString}
        // onSearch={setSearchString}
        children: 
          React.createElement(Space, { direction: "vertical", __self: this, __source: {fileName: _jsxFileName, lineNumber: 133}}
            , React.createElement('div', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 134}}
              , React.createElement(DatePicker.RangePicker, {
                value: [start, end],
                onChange: ([start, end]) => {
                  navigate(
                    `/overview/${start.format(DATE_FORMAT)}/${end.format(
                      DATE_FORMAT
                    )}?${searchParams.toString()}`
                  );
                }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 135}}
              )
            )

            , React.createElement('div', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 147}}
              , React.createElement(Checkbox, {
                checked: searchParams.get("showFinished") === "true",
                onChange: (e) => {
                  searchParams.set(
                    "showFinished",
                    e.target.checked ? "true" : "false"
                  );

                  setSearchParams(searchParams);
                },
                children: t("app:overview.show_finished"), __self: this, __source: {fileName: _jsxFileName, lineNumber: 148}}
              )
            )
          )
        , __self: this, __source: {fileName: _jsxFileName, lineNumber: 123}}
      )

      , React.createElement(Tabs, { activeKey: tab, onChange: setTab, __self: this, __source: {fileName: _jsxFileName, lineNumber: 165}}
        , React.createElement(Tabs.TabPane, { tab: t("app:overview.auftrag_tab"), key: "aufträge", __self: this, __source: {fileName: _jsxFileName, lineNumber: 166}}
          , React.createElement(OrdersList, { rows: aufträge.result, loading: aufträge.loading, __self: this, __source: {fileName: _jsxFileName, lineNumber: 167}} )
        )
        , React.createElement(Tabs.TabPane, { tab: t("app:overview.artikel_tab"), key: "artikel", __self: this, __source: {fileName: _jsxFileName, lineNumber: 169}}
          , React.createElement(List, {
            itemLayout: "vertical",
            loading: artikelAufträge.loading,
            dataSource: artikelGroupMenge,
            rowKey: (row) => row.artikel.id,
            locale: {
              emptyText: t("app:articles.list_empty"),
            },
            renderItem: (row) => {
              const name = row.artikel.get("Name");
              const nummer = row.artikel.get("Artikelnummer");
              const title = `${name} (#${nummer})`;

              const description = `Aufträge: ` + row.aufträge.join(", ");

              const einheit = row.artikel.get("Einheit");
              const menge = `${row.menge} ${einheit}`;

              return (
                React.createElement(List.Item, {
                  extra: 
                    React.createElement('span', { style: { fontWeight: "bold", marginLeft: 10 }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 191}}
                      , menge
                    )
                  , __self: this, __source: {fileName: _jsxFileName, lineNumber: 189}}

                  , React.createElement(List.Item.Meta, { title: title, description: description, __self: this, __source: {fileName: _jsxFileName, lineNumber: 196}} )

                  , React.createElement('div', {
                    dangerouslySetInnerHTML: {
                      __html: row.artikel.get("Beschreibung4HTML"),
                    }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 198}}
                  )
                )
              );
            }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 170}}
          )
        )
      )
    )
  );
});
