import Parse from "parse";

















export class Auftrag extends Parse.Object {
  static __initStatic() {this.className = "Auftrag"}

  constructor(data) {
    super("Auftrag", data );
  }

  get Datum() {
    return super.get("Datum");
  }
  set Datum(value) {
    super.set("Datum", value);
  }
  get DatumMitUhrzeit() {
    return super.get("DatumMitUhrzeit");
  }
  set DatumMitUhrzeit(value) {
    super.set("DatumMitUhrzeit", value);
  }
  get Kunde() {
    return super.get("Kunde");
  }
  set Kunde(value) {
    super.set("Kunde", value);
  }
  get Minute() {
    return super.get("Minute");
  }
  set Minute(value) {
    super.set("Minute", value);
  }
  get Monteure() {
    return super.get("Monteure");
  }
  set Monteure(value) {
    super.set("Monteure", value);
  }
  get Stunde() {
    return super.get("Stunde");
  }
  set Stunde(value) {
    super.set("Stunde", value);
  }
} Auftrag.__initStatic();

Parse.Object.registerSubclass("Auftrag", Auftrag);
