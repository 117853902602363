const _jsxFileName = "/home/runner/work/heinzerling-web/heinzerling-web/app/js/components/OrdersList.tsx";import React from "react";

import { useTranslation } from "@opendash/core";
import { Button, Divider, List, Tag } from "antd";

import { Icon } from "@opendash/icons";
import { Link, useNavigate } from "react-router-dom";
import { formatDateTime } from "../helper";
import styled from "styled-components";

const Actions = styled.div`
  display: flex;
  gap: 10px;

  @media only screen and (max-device-width: 600px) {
    width: 100%;
    margin-top: 10px;
    flex-direction: column;
  }
`;







export const OrdersList = React.memo(function OrdersList({
  rows,
  loading,
  hideElevatorLink,
}) {
  const t = useTranslation();
  const navigate = useNavigate();

  return (
    React.createElement(List, {
      loading: loading,
      dataSource: rows,
      locale: {
        emptyText: t("app:orders.list_empty"),
      },
      rowKey: (row) => row.id,
      footer: 
        React.createElement(React.Fragment, null
          , React.createElement(Divider, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 46}} )
          , React.createElement('div', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 47}}
            , React.createElement(Icon, { icon: "fa:check-double", style: { color: "green" }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 48}} ), " = Arbeiten abgeschlossen + Arbeitsbericht versandt"

          )
          , React.createElement('div', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 51}}
            , React.createElement(Icon, { icon: "fa:check", style: { color: "#ffc107" }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 52}} ), " = Arbeitsbericht versandt"

          )
          /* <div>
            <Icon icon="fa:clock" /> = Keine der oberen Bedingungen
          </div> */
        )
      ,
      pagination: { pageSize: 20 },
      renderItem: (row) => {
        const auftrag = row ;
        const kunde = auftrag.get("Kunde") ;
        const aufzug = auftrag.get("Aufzug") ;
        return (
          React.createElement(Link, { to: `/orders/${auftrag.id}`, __self: this, __source: {fileName: _jsxFileName, lineNumber: 66}}
            , React.createElement(List.Item, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 67}}
              , React.createElement(List.Item.Meta, {
                avatar: 
                  auftrag.get("Abgeschlossen") && !!auftrag.get("mailSend") ? (
                    React.createElement(Icon, {
                      icon: "fa:check-double",
                      style: {
                        color: "green",
                        fontSize: "16px",
                        height: "48px",
                        lineHeight: "48px",
                      }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 71}}
                    )
                  ) : auftrag.get("mailSend") ? (
                    React.createElement(Icon, {
                      icon: "fa:check",
                      style: {
                        color: "#ffc107",
                        fontSize: "16px",
                        height: "48px",
                        lineHeight: "48px",
                      }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 81}}
                    )
                  ) : (
                    React.createElement(Icon, {
                      icon: "fa:clock",
                      style: {
                        fontSize: "16px",
                        height: "48px",
                        lineHeight: "48px",
                      }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 91}}
                    )
                  )
                ,
                title: 
                  React.createElement('span', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 102}}, "Auftrag #"
                     , auftrag.get("Nummer"), " "
                    , React.createElement(Tag, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 104}}, formatDateTime(auftrag.get("DatumMitUhrzeit"))), " "
                    , React.createElement(Tag, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 105}}, auftrag.get("Typ")), " "
                  )
                ,
                description: 
                  aufzug ? (
                    React.createElement('span', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 110}}
                      , kunde.get("Name"), " - "  , aufzug.get("Strasse"), " "
                      , aufzug.get("PLZ"), " " , aufzug.get("Ort")
                    )
                  ) : (
                    kunde.get("Name")
                  )
                , __self: this, __source: {fileName: _jsxFileName, lineNumber: 68}}
              )

              , React.createElement(Actions, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 120}}
                , !!aufzug && !hideElevatorLink && (
                  React.createElement(Button, {
                    key: "elevator",
                    icon: React.createElement(Icon, { icon: "fa:external-link", __self: this, __source: {fileName: _jsxFileName, lineNumber: 124}} ),
                    children: t("app:admin.elevators.view_title", {
                      name: aufzug.get("Nummer") || "",
                    }),
                    onClick: (e) => {
                      e.preventDefault();
                      navigate(`/elevators/${aufzug.id}`);
                    }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 122}}
                  )
                )
                /* <Button
                key="print"
                type="primary"
                icon={<Icon icon="fa:print" />}
                children={t("app:admin.order.action_print")}
                onClick={() => {
                  navigate(`/print-order/${auftrag.id}`);
                }}
              /> */
                , React.createElement(Button, {
                  key: "show",
                  type: "primary",
                  // icon={<Icon icon="fa:print" />}
                  children: t("app:admin.order.action_show"),
                  onClick: () => {
                    // navigate(`/orders/${auftrag.id}`);
                  }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 143}}
                )
              )
            )
          )
        );
      }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 37}}
    )
  );
});
