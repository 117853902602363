const _jsxFileName = "/home/runner/work/heinzerling-web/heinzerling-web/app/js/components/OrdersRoute.tsx"; function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }import React from "react";
import Parse from "parse";

import {
  useTranslation,
  AdminLayout,
  search,
  useUrlParam,
} from "@opendash/core";
import { AdminToolbar } from "@opendash/ui";
import { $parse } from "@opendash/plugin-parse";
import { Button } from "antd";
import { useParseQuery } from "parse-hooks";
import { Einzelauftrag, } from "../parse";
import { Icon } from "@opendash/icons";
import { OrdersList } from "./OrdersList";

export const OrdersRoute = React.memo(function OrdersRoute() {
  const t = useTranslation();

  const [searchString, setSearchString] = useUrlParam(
    "query",
    "",
    "string"
  );

  const [hiddenQuery] = useUrlParam("hiddenQuery", "", "string");

  const query = React.useMemo(
    () =>
      new Parse.Query(Einzelauftrag)
        .exists("DatumMitUhrzeit")
        .descending("DatumMitUhrzeit")
        .include("Kunde")
        .include("Aufzug")
        .limit(1000000),
    []
  );

  const entries = useParseQuery(query, {
    // live: true,
    // liveReload: true,
    // reloadThrottle: 5000,
  });

  const rows = React.useMemo(
    () =>
      search(searchString + " " + hiddenQuery, entries.result, (row) => {
        const auftrag = row ;
        const kunde = auftrag.get("Kunde") ;
        const aufzug = auftrag.get("Aufzug") ;

        return {
          text: [
            _optionalChain([auftrag, 'access', _ => _.get, 'call', _2 => _2("Nummer"), 'optionalAccess', _3 => _3.toString, 'call', _4 => _4()]),
            _optionalChain([kunde, 'optionalAccess', _5 => _5.get, 'call', _6 => _6("Name"), 'optionalAccess', _7 => _7.toLowerCase, 'call', _8 => _8()]),
            _optionalChain([aufzug, 'optionalAccess', _9 => _9.get, 'call', _10 => _10("Nummer"), 'optionalAccess', _11 => _11.toString, 'call', _12 => _12()]),
          ],
          keys: {
            monteur: _optionalChain([auftrag, 'access', _13 => _13.get, 'call', _14 => _14("Monteure"), 'optionalAccess', _15 => _15.split, 'call', _16 => _16("; ")]),
            versendet: auftrag.get("mailSend") ? "ja" : "nein",
            abgeschlossen: auftrag.get("Abgeschlossen") ? "ja" : "nein",
          },
        };
      }).sort((a, b) => {
        return (
          b.get("DatumMitUhrzeit").valueOf() -
          a.get("DatumMitUhrzeit").valueOf()
        );
      }),
    [entries.result, searchString, hiddenQuery]
  );

  return (
    React.createElement(AdminLayout, { contentPadding: true, __self: this, __source: {fileName: _jsxFileName, lineNumber: 75}}
      , React.createElement(AdminToolbar, {
        title: t("app:admin.order.title"),
        description: t("app:admin.order.description"),
        padding: false,
        actions: [
          React.createElement(Button, {
            key: "new",
            type: "primary",
            icon: React.createElement(Icon, { icon: "fa:plus", __self: this, __source: {fileName: _jsxFileName, lineNumber: 84}} ),
            children: t("app:admin.order.action_new"),
            onClick: () => {
              $parse.ui.createObject("Auftrag").then((objectId) => {
                entries.reload();
              });
            }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 81}}
          ),
        ],
        searchInitial: searchString,
        onSearch: setSearchString, __self: this, __source: {fileName: _jsxFileName, lineNumber: 76}}
      )

      , React.createElement(OrdersList, { rows: rows, loading: entries.loading, __self: this, __source: {fileName: _jsxFileName, lineNumber: 97}} )
    )
  );
});
